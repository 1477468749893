
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'

  import { MdxLayout } from '@redocly/developer-portal/ui';
import {
  Alert,
  LandingLayout,
  Button,
  Link,
  Box,
  FlexSection,
  Flex,
  Jumbotron,
  Typography,
  ThinTile,
  WideTile,
  Emphasis,
  H1,
  H2,
  SectionHeader,
  NavBar,
} from "@redocly/developer-portal/ui";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = MdxLayout
  }
  render() {
    const props = this.props
    const components = { wrapper: React.Fragment };


    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)}
             components={components}>


<MDXTag name="h1" components={components} props={{"style":{"position":"relative"}}}><MDXTag name="a" components={components} parentName="h1" props={{"href":"#overview","aria-label":"overview permalink","className":"anchor before"}}><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></MDXTag><MDXTag name="div" components={components} parentName="h1" props={{"class":"hidden-anchor","id":"overview"}}></MDXTag>{`Overview`}</MDXTag>
<MDXTag name="p" components={components}><H2>{`A quick how-to guide to get you started building with the Totogi API Platform  `}<MDXTag name="a" components={components} parentName="p" props={{"href":"./#about-totogi-preview-release"}}>{`Preview Edition`}</MDXTag></H2></MDXTag>
<Alert variant="warning">
  Not a Totogi API Developer?
  <Link to="https://www.totogi.com/contact-us/"> Contact us</Link> for free
  access to our Preview Edition and start experimenting with Totogi APIs.
</Alert>
<MDXTag name="p" components={components}>{`These simple steps will make sure you have everything you need to start building with Totogi API Platform.`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"/developer-portal/quickstart/getapikeys/"}}>{`Get your API keys`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"/developer-portal/quickstart/makeapirequest/"}}>{`Make API Requests`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"/developer-portal/quickstart/gethelp/"}}>{`Get Help`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="h1" components={components} props={{"style":{"position":"relative"}}}><MDXTag name="a" components={components} parentName="h1" props={{"href":"#navigating-around","aria-label":"navigating around permalink","className":"anchor before"}}><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></MDXTag><MDXTag name="div" components={components} parentName="h1" props={{"class":"hidden-anchor","id":"navigating-around"}}></MDXTag>{`Navigating Around`}</MDXTag>
<MDXTag name="p" components={components}>{`On the left hand side you'll find the index of available APIs with reference documentation. You can also try the APIs using interactive API console within the documentation.`}</MDXTag>
<MDXTag name="h1" components={components} props={{"style":{"position":"relative"}}}><MDXTag name="a" components={components} parentName="h1" props={{"href":"#about-totogi-preview-release","aria-label":"about totogi preview release permalink","className":"anchor before"}}><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></MDXTag><MDXTag name="div" components={components} parentName="h1" props={{"class":"hidden-anchor","id":"about-totogi-preview-release"}}></MDXTag>{`About Totogi Preview Release`}</MDXTag>
<MDXTag name="p" components={components}>{`As defined in the preview edition software agreement you have previously signed, Totogi API is currently in limited preview. By using the preview edition, developers agree that:`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">{`Documentation is incomplete and will be constantly updated as we develop the platform. Your questions and feedback will help us improve it rapidly;`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Data may be deleted without warning or notice;`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`The Totogi team will not provide any restoration of previously used environments;`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Developers are not building production systems before they enter into a commercial agreement;`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Functionality is limited to the implementations of the TM Forum APIs for reading and writing domain specific data.`}</MDXTag>
</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  